.App {
  text-align: center;
}






#toolbar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

}

@media only screen and (max-width: 750px) {
  #toolbar {
    flex-direction: column;
  }
#logoImg{
  height: 15vh !important;
  width: 20vh !important;
}
  .button {
    height: 12vw !important;
  }
}

.button {
  background-color: rgb(112, 77, 23);
  /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: "auto"
}